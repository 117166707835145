<template>
  <div class="container">
    <vue-element-loading :active="running" spinner="ring" background-color="rgba(255,255,255,0.6)" size="400" color="#36F"/>
    <b-navbar>
      <b-navbar-nav class="ml-auto">
        <b-nav-item @click="setLocale">{{$t('message.Change_Language_To')}} <b-avatar size="2.5em" :text="$t('message.languageto')"></b-avatar></b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <div class="xnpanel">
      <form>
        <b-form-group>
          <p class="mt-2">&nbsp;</p>
          <b-form-input size="lg" type="text" v-model="uid" :placeholder="$t('message.login_uid')" required maxlength="32"></b-form-input>
        </b-form-group>
        <b-form-group>
          <p class="mt-2">&nbsp;</p>
          <b-form-input size="lg" type="password" autocomplete="false" v-model="pas" :placeholder="$t('message.login_passwd')" required maxlength="16"></b-form-input>
        </b-form-group>
        <b-form-group class="ml-2">
          <p class="mt-2">&nbsp;</p>
          <b-form-checkbox v-model="savepw">{{$t('message.login_save_pwd')}}</b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <p class="mt-2">&nbsp;</p>
          <b-button block class="mainbtn" variant="primary" @click="loginow" :disabled="running">{{$t('message.login_btn_now')}}
          </b-button>
        </b-form-group>
        <b-form-group class="mt-2">
          <div class="loginmsg">{{errormsg}}&nbsp;</div>
          <div class="text-right m-3">
            <b-button :to="{path:'/register',query:{backid:$route.query.backid}}" variant="primary mr-2">{{$t('message.login_btn_register')}}</b-button>
            <b-button :to="'/helper'+$i18n.locale" variant="primary">{{$t('message.login_btn_help')}}</b-button>
          </div>
        </b-form-group>
      </form>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { APPVERSION } from '@/config'
  import { recaptchasitekey } from '@/config'
  import { VueReCaptcha } from 'vue-recaptcha-v3'
  Vue.use(VueReCaptcha, { siteKey: recaptchasitekey, loaderOptions: {useRecaptchaNet: true} });
  export default {
    name: 'login',
    mounted() {
    },
    data() {
      return {
        running: false,
        savepw: localStorage.spw==undefined?true:localStorage.spw,
        uid: localStorage.uid,
        pas: localStorage.pas,
        errormsg: APPVERSION
      };
    },
    methods: {
      async loginow() {
        if (!this.uid || this.uid.length<3) {
          this.errormsg = this.$t('message.login_uid_too_short');
        } else if (!this.pas || this.pas.length<5) {
          this.errormsg = this.$t('message.login_pas_too_short');
        } else if (this.uid.indexOf('@')<2 || this.uid.indexOf('.')<5) {
          this.errormsg = this.$t('message.uid_invalidemail');
        } else {
          this.errormsg = '';
          this.running = true;
          await this.$recaptchaLoaded();
          let recaptoken = await this.$recaptcha('login');
          let params = 'uid='+this.uid+'&pas='+this.$md5(this.pas)+'&recapt='+recaptoken;
          let axresp = await this.axios.post('/login?tm='+new Date().getTime(), params);
          this.running = false;
          if (axresp && axresp.status == 200) {
            if (axresp.data.rc==1) {
              localStorage.setItem('token', axresp.data.token);
              localStorage.setItem('nickname',axresp.data.nickname);
              if (this.savepw) {
                localStorage.setItem('spw', this.savepw);
                localStorage.setItem('uid', this.uid);
                localStorage.setItem('pas', this.pas);
              } else {
                localStorage.removeItem('spw');
                localStorage.removeItem('uid');
                localStorage.removeItem('pas');
              }
              if(this.$route.query.backid){
                if (Number(this.$route.query.backid)<50000) {
                  this.$router.push('chargerbk/'+this.$route.query.backid);
                } else {
                  this.$router.push('chargerev/'+this.$route.query.backid);
                }
              } else {
                this.$router.push('admusers');
              }
            } else {
              this.errormsg = axresp.data.rm;
            }
          } else {
              this.errormsg = 'ERROR: network connection lose.';
          }
        }
      },
      setLocale() {
        if (this.$i18n.locale=='EN') {
          localStorage.setItem('locale', 'FR');
          this.$i18n.locale='FR';
        } else if (this.$i18n.locale=='FR') {
          localStorage.setItem('locale', 'CN');
          this.$i18n.locale='CN';
        } else {
          localStorage.setItem('locale', 'EN');
          this.$i18n.locale='EN';
        }
      },
    },
  }
</script>
